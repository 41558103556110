import React from "react";
import cl from './Social.module.css';
import { UilWhatsapp, UilPhoneVolume, UilTelegramAlt, UilAt, UilVkAlt, UilYoutube  } from '@iconscout/react-unicons';


const Social = () => {
    return(
        <div className={cl.social}>
            <a className={cl.social__circle} href="tel:+79034584095">
                <UilPhoneVolume />
            </a>
            <a className={cl.social__circle} href="https://api.whatsapp.com/send?phone=79034584095" target="blank"> 
                <UilWhatsapp />
            </a>
            
            <a className={cl.social__circle} href="https://t.me/build_root" target="blank">
                <UilTelegramAlt />
            </a>
            <a className={cl.social__circle} href="mailto:info@build-root.ru">
                <UilAt />
            </a>
            <a className={cl.social__circle} href="https://vk.com/b_root" target="blank">
                <UilVkAlt />
            </a>
            <a className={cl.social__circle} href="https://www.youtube.com/@yuralizun9529" target="blank">
                <UilYoutube/>
            </a>
        </div>
    )
}
export default Social
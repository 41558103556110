import React  from 'react'
import cl from './Header.module.css'
import Navigation from '../../UI/navigation/Navigation'
import MobilMenu from '../../UI/mobilMenu/MobilMenu'


const Header = () => {

    return (
      <header className={cl.header}>
        
        <nav className={cl.header__nav}>
          <div className={cl.header__nav__pc}>
            <Navigation />
          </div>
          <div className={cl.header__nav__mobil}>
            <MobilMenu />
          </div>
        </nav>
      </header>
    )
}

export default Header
import React from 'react'
import cl from './Page404.module.css'
import { Link } from 'react-router-dom';

const Page404 = () => {

    return (
      <div className={cl.page}>
        <div className={cl.page__ufo}>
            <div className={cl.page__ufo__roof}></div>
            <div className={cl.page__ufo__ray}></div>
            <div className={cl.page__ufo__bottom}>
                <div className={`${cl.page__ufo__circle} ${cl.box1}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box2}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box3}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box4}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box5}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box6}`}></div>
                <div className={`${cl.page__ufo__circle} ${cl.box7}`}></div>
            </div>
            
        </div>
        <div className={cl.page__title}>
            <h1>404</h1>
            <h2>Страница не найдена</h2>
        </div>
        <div className={cl.page__home}>
            <Link to='/' className={cl.page__home__link}>
                <p>Домой</p>
            </Link>
        </div>
      </div>
    )

}

export default Page404
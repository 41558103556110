import React from 'react'
import cl from '../confidentiality/ListStyle.module.css'
import LinkToHome from '../../UI/linkToHome/LinkToHome';

const Usage = () => {

    return (
      <div className={` container ${cl.base}`}>
        <h1>Условия использования сайта build-root.ru</h1>
        <LinkToHome textLink={'Вернуться на сайт'} />
        <h5>1. Общие положения</h5>
        <ol>
            <li>Настоящие Условия использования (далее - "Условия") регулируют отношения между Лизун Юрий Станиславович (далее - "Оператор") и Пользователем (физическим или юридическим лицом) в связи с использованием Сайта https://build-root.ru (далее - "Сайт").</li>
            <li>Использование Сайта Пользователем означает его согласие с настоящими Условиями.</li>
            <li>Оператор оставляет за собой право вносить изменения в настоящие Условия. Новая редакция Условий вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Условий.</li>
            <li> В случае несогласия с настоящими Условиями Пользователь должен прекратить использование Сайта.</li>
            <li>Все материалы, используемые на Сайте, были получены по лицензии MIT. Лицензия MIT предоставляет Оператору право на использование материалов Сайта в соответствии с условиями данной лицензии.</li>
        </ol>
        <h5>2. Предмет Условий использования</h5>
        <ol>
            <li> Настоящие Условия определяют порядок и условия использования Сайта Пользователем.</li>
            <li>Оператор предоставляет Пользователю доступ к Сайту и его функциональным возможностям на условиях, предусмотренных настоящими Условиями.</li>
            <li>Пользователь обязуется использовать Сайт в соответствии с настоящими Условиями и действующим законодательством Российской Федерации.</li>
            <li>Оператор не несет ответственности за возможные убытки, которые могут возникнуть в результате использования Сайта.</li>
        </ol>
        <h5>3. Права и обязанности Оператора</h5>
        <ol>
            <li>Оператор имеет право:
                <ul>
                    <li>Вносить изменения в Сайт и его функциональные возможности.</li>
                    <li>Ограничивать доступ Пользователя к Сайту в случае нарушения настоящих Условий.</li>
                    <li>Использовать информацию, предоставленную Пользователем, в соответствии с Политикой конфиденциальности.</li>
                </ul>
            </li>
        </ol>
        <h5> 4. Права и обязанности Пользователя</h5>
        <ol>
            <li>Пользователь имеет право:
                <ul>
                    <li>Использовать Сайт в соответствии с его функциональными возможностями.</li>
                    <li>Обращаться к Оператору с вопросами и предложениями по электронной почте или через форму обратной связи на Сайте.</li>
                </ul>
            </li>
            <li>Пользователь обязуется:
                <ul>
                    <li>Соблюдать настоящие Условия использования.</li>
                    <li>Не использовать Сайт для незаконных целей.</li>
                    <li>Не нарушать права третьих лиц при использовании Сайта.</li>
                    <li>Не распространять информацию, которая является незаконной, оскорбительной, угрожающей, клеветнической, нарушающей права интеллектуальной собственности или иным образом нарушающей законодательство Российской Федерации.</li>
                    <li>Не распространять спам, рекламные сообщения, нежелательную почту или другие формы навязчивой рекламы.</li>
                    <li>Не использовать Сайт для совершения мошеннических действий, фишинга или других видов мошенничества.</li>
                </ul>
            </li>
        </ol>
        <h5>5. </h5>
        <ol>
            <li>Оператор не несет ответственности за возможные убытки, которые могут возникнуть в результате использования Сайта.</li>
            <li>Пользователь несет ответственность за нарушение настоящих Условий и возможные последствия такого нарушения.</li>
            <li>В случае нарушения настоящих Условий Оператор имеет право ограничить доступ Пользователя к Сайту.</li>
        </ol>
        <h5>6. Заключительные положения</h5>
        <ol>
            <li>Настоящие Условия являются неотъемлемой частью Политики конфиденциальности.</li>
            <li>Все споры между Оператором и Пользователем разрешаются путем переговоров.</li>
            <li>К настоящим Условиям и отношениям между Оператором и Пользователем применяется действующее законодательство Российской Федерации.</li>
        </ol>
        <LinkToHome textLink={'Вернуться на сайт'} />
      </div>
    )
}

export default Usage
import React from 'react'
import cl from './ListStyle.module.css';
import LinkToHome from '../../UI/linkToHome/LinkToHome';

const Confidentiality = () => {

    return (
      <div className={` container ${cl.base}`}>
        <h1>Политка конфиденциальности</h1>
        <LinkToHome textLink={'Вернуться на сайт'} />
        <h5>1. Общие положения</h5>
        <ol>
            <li> Настоящая Политика конфиденциальности (далее - "Политика") разработана в соответствии с требованиями Федерального закона от 27.07.2006 №152-ФЗ "О персональных данных" и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, которые получает и обрабатывает Лизун Юрий Станиславович (далее - "Оператор").</li>
            <li>. Политика действует в отношении всей информации, которую Оператор может получить о Пользователе во время использования им сайта https://build-root.ru/ (далее - "Сайт").</li>
            <li> Использование Сайта Пользователем означает согласие с настоящей Политикой и условиями обработки персональных данных Пользователя.</li>
            <li> В случае несогласия с условиями Политики Пользователь должен прекратить использование Сайта.</li>
            <li> Оператор вправе вносить изменения в Политику. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.</li>
        </ol>
        <h5>2. Предмет Политики конфиденциальности</h5>
        <ol>
            <li> Настоящая Политика устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет при использовании Сайта.</li>
            <li>Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются Пользователем путём заполнения формы обратной связи на Сайте и могут включать в себя следующую информацию:
                <ol>
                    <li>фамилию, имя, отчество Пользователя;</li>
                    <li>контактный телефон Пользователя;</li>
                    <li>адрес электронной почты (e-mail);</li>
                    <li>наименование организации;</li>
                    <li>иную информацию, которую Пользователь добровольно предоставляет.</li>
                </ol>
            </li>
        </ol>
        <h5>3. Цели сбора персональных данных</h5>
        <ol>
            <li>Оператор собирает и хранит только те персональные данные, которые необходимы для предоставления услуг Пользователю и для исполнения соглашений и договоров с Пользователем.</li>
            <li>Персональные данные Пользователя Оператор может использовать в следующих целях:
                <ol>
                    <li>Идентификации Пользователя, зарегистрированного на Сайте.</li>
                    <li>Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
                    <li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработки запросов и заявок от Пользователя.</li>
                    <li>Уведомления Пользователя Сайта о новостях и изменениях в работе Сайта.</li>
                    <li>Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</li>
                    <li>Осуществления рекламной деятельности с согласия Пользователя.</li>
                </ol>
            </li>
        </ol>
        <h5>4. Способы и сроки обработки персональных данных</h5>
        <ol>
            <li>Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
            <li>Пользователь соглашается с тем, что Оператор вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно для целей выполнения заказа Пользователя, оформленного на Сайте.</li>
            <li>Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</li>
            <li>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</li>
            <li>Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
            <li>Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</li>
        </ol>
        <h5>5. Права и обязанности сторон</h5>
        <ol>
            <li>Пользователь вправе:
                <ul>
                    <li>Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования Сайта, и давать согласие на их обработку.</li>
                    <li>Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</li>
                    <li>Пользователь имеет право на получение у Оператора информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</li>
                </ul>
            </li>
            <li>Оператор обязан:
                <ul>
                    <li>спользовать полученную информацию исключительно для целей, указанных в п. 3 настоящей Политики конфиденциальности.</li>
                    <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 4.2 и 4.3 настоящей Политики.</li>
                    <li>Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</li>
                    <li>Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</li>
                </ul>
            </li>
        </ol>
        <h5>6. Ответственность сторон</h5>
        <ol>
            <li>Оператор, не исполнивший свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2 и 7 настоящей Политики.</li>
            <li>В случае утраты или разглашения Конфиденциальной информации Оператор не несёт ответственность, если данная конфиденциальная информация:
                <ul>
                    <li>Стала публичным достоянием до её утраты или разглашения.</li>
                    <li>Была получена от третьей стороны до момента её получения Оператором.</li>
                    <li>Была разглашена с согласия Пользователя.</li>
                </ul>
            </li>
            <li> Пользователь несет полную ответственность за соблюдение требований законодательства РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.</li>
            <li>Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части Сайта, несет лицо, предоставившее такую информацию.</li>
            <li>Пользователь соглашается, что информация, предоставленная ему как часть Сайта, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на Сайте.</li>
        </ol>
        <h5>7. Политика в отношении файлов cookie</h5>
        <ol>
            <li>Оператор использует файлы cookie для улучшения качества Сайта, удобства его использования, а также для сбора статистической информации.</li>
            <li>Файлы cookie представляют собой небольшие файлы, которые сохраняются на устройстве Пользователя при посещении Сайта. Они используются для идентификации Пользователя и сохранения его предпочтений и настроек</li>
            <li>Оператор использует следующие типы файлов cookie:
                <ul>
                    <li> Строго необходимые файлы cookie: Эти файлы cookie необходимы для работы Сайта и не могут быть отключены в настройках браузера. Они включают в себя файлы cookie, которые обеспечивают основные функциональные возможности Сайта, такие как навигация по страницам и доступ к защищенным областям Сайта.</li>
                    <li>Аналитические файлы cookie: Эти файлы cookie собирают информацию о том, как Пользователь использует Сайт, включая информацию о страницах, которые посетил Пользователь, времени, проведенном на Сайте, и о том, какие ссылки Пользователь нажимал. Эта информация используется для анализа и улучшения работы Сайта.</li>
                    <li>Функциональные файлы cookie: Эти файлы cookie позволяют Сайту запоминать выбор Пользователя, такие как имя пользователя, язык или регион, чтобы предоставить Пользователю более персонализированный опыт.</li>
                </ul>
            </li>
            <li>Пользователь может настроить свой браузер таким образом, чтобы отказаться от всех файлов cookie или получать уведомления о получении файлов cookie. Однако, если Пользователь откажется от использования файлов cookie, некоторые функции Сайта могут быть недоступны или работать некорректно.</li>
        </ol>
        <h5>8. Заключительные положения</h5>
        <ol>
            <li>Политика конфиденциальности является неотъемлемой частью Пользовательского соглашения (Публичной оферты) и действует в течение всего периода использования Сайта Пользователем.</li>
            <li>Оператор вправе вносить изменения в Политику конфиденциальности. Новая редакция Политики конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</li>
            <li>Все споры между сторонами разрешаются путем переговоров. При невозможности достижения согласия спор передается на рассмотрение в судебные органы в соответствии с действующим законодательством Российской Федерации.</li>
            <li>К настоящей Политике и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.</li>
        </ol>
        <LinkToHome textLink={'Вернуться на сайт'} />
      </div>
    )
}

export default Confidentiality
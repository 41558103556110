const landing = (
    <>
        <p>
            Лендинг страница, он же сайт визитка - это веб-страница, цель которой - привлечение внимания к нашему продукту или услуге и стимулирование посетителей к совершению конкретного действия, например, заполнению формы или покупке товара.
        </p>
        <p>
            На лендинг странице размещяют полезную информацию о продукте, его особенностях и преимуществах. Рассказывают о продукте, как он может решить ваши проблемы и удовлетворить ваши потребности. Все просто и понятно, без сложных терминов и ненужной информации.
        </p>
        <p>
            Мы гордимся своим качеством и надежностью. Наши клиенты доверяют нам, потому что мы предлагаем решения, которые работают и приносят реальные результаты. Наши цены доступны для всех, а качество наших услуг остается на высоком уровне.
        </p>
    </>
)
const portal = (
    <>
        <p>
            Интернет-портал это специальный веб-сайт, объединяющий различные сервисы, информацию и ресурсы в одном месте.
        </p>
        <ul>
            <li>
                Актуальные новости и статьи по различным темам.
            </li>
            <li>
                Удобный поиск информации по категориям.
            </li>
            <li>
                Возможность общения с другими пользователями через форумы и чаты.
            </li>
            <li>
                Полезные сервисы, такие как погода, курсы валют, онлайн-карты и многое другое.
            </li>
            <li>
                Персонализированные рекомендации и подборки контента.
            </li>
        </ul>
        <p>
            Интернет-портал управляется с помощью современной системы управления контентом (CMS), которая обеспечивает удобное и эффективное управление всеми аспектами портала. Это означает, можно быстро обновлять информацию, добавлять новые функции и поддерживать портал в актуальном состоянии, чтобы ваши посетители всегда получали самую свежую и интересную информацию.
        </p>
    </>
)
const develop = (
    <>
        <p>
            Веб-приложение и как оно может быть полезно для вас? Веб-приложение - это программное обеспечение, которое работает в вашем веб-браузере и предоставляет удобный доступ к различным функциям и сервисам прямо через интернет. Предлагают широкий спектр функций, которые помогут вам решить различные задачи, начиная от управления проектами и заканчивая аналитикой данных.
        </p>
        <p>
            Приложения имеют интуитивно понятный дизайн и простой интерфейс, который позволит вам быстро освоиться с ними.
        </p>
        <p>
            Гибкость и масштабируемость: Наши веб-приложения легко масштабируются в зависимости от ваших потребностей и растущих требований бизнеса. Мы также предлагаем гибкие настройки и возможности кастомизации, чтобы вы могли адаптировать приложение под свои уникальные потребности.
        </p>
        <p>
            Безопасность: Мы обеспечиваем высокий уровень безопасности для ваших данных и информации. Наши приложения используют современные методы шифрования и защиты данных, чтобы обеспечить полную конфиденциальность и безопасность вашей информации.
        </p>
        <p>
            Не упустите возможность улучшить ваш бизнес с помощью наших веб-приложений! 
        </p>
    </>
)
const advertising = (
    <>
        <p>
            Реклама в интернете - это способ привлечения внимания к вашему бренду или продукту через различные онлайн-каналы. Мы используем разнообразные технические инструменты и платформы для того, чтобы ваша реклама была видна вашей целевой аудитории.
        </p>
        <p>
            <span>Наши услуги рекламы в интернете включают в себя:</span>
        </p>
        <ul>
            <li>
                <span>Таргетированная реклама: </span>Настраиваем рекламные кампании так, чтобы они были направлены именно на вашу целевую аудиторию, учитывая их интересы, поведение и характеристики.
            </li>
            <li>
                <span>Поисковая реклама: </span>Используем контекстную рекламу на поисковых системах, чтобы ваше объявление появлялось в результатах поиска по ключевым словам, связанным с вашим бизнесом.
            </li>
            <li>
                <span>Отслеживание и аналитика: </span>Предоставляем вам подробные отчеты о результативности ваших рекламных кампаний, а также аналитику о поведении пользователей, чтобы вы могли улучшить эффективность своей рекламы.
            </li>
        </ul>
        <p>С нами ваша реклама будет действенной и результативной! Обратитесь к нам уже сегодня и начните привлекать больше клиентов через интернет.</p>
    </>
)

const list = [
    {   
        label : 'лендинг' ,
        description: landing,
        children : 'лендинга',
    },
    {
        label : 'интернет портал',
        description: portal,
        children : 'интернет портала', 
    },
    {
        label : 'веб приложения',
        description: develop,
        children : 'веб приложения',
    },
    {
        label : 'реклама в интернете',
        description: advertising,
        children : 'размещения рекламы',
    },
  ]

export default list;

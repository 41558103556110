import React from 'react'
import CustomForm from '../../UI/form/CustomForm'
import { UilTimesCircle } from '@iconscout/react-unicons'
import cl from './CustomModal.module.css'
import { Transition } from 'react-transition-group'

const CustomModal = ({isOpen, onClose}) => {

    const handleClose = (e) => {
        e.stopPropagation(); // Предотвращаем всплытие события
        onClose();
    }

    return (
        <Transition
            in={isOpen}
            timeout={350}
            unmountOnExit={true}
        >
            {(state) => (
                <div onClick={() => onClose()} className={`${cl.modal} cl.modal__${state}`}>
                    <div className={cl.modal__body} onClick={(e) => e.stopPropagation()}>
                        <UilTimesCircle 
                            onClick={handleClose} 
                            className={cl.modal__close} 
                        />
                        <div className={cl.modal__body__mix}>
                            <CustomForm titleForm={'Рассчитать стоимость'}/>
                        </div>
                    </div>
                </div>
            )}
        </Transition>
    )
}

export default CustomModal
